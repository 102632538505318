import React, { Component } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import NavNotification from './NavNotification'; // Import NavNotification
import NavLanguage from './NavLanguage'; // Import NavNotification
import { connect } from "react-redux";
import { DIR_RTL } from 'constants/ThemeConstant';

export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
        <Menu.Item>
           {/* Use NavLanguage here */}
            {/* <NavLanguage /> */}
         </Menu.Item>
        <Menu.Item>
           {/* Use NavNotification here */}
           <NavNotification />
         </Menu.Item>
         
          <Menu.Item key="panel" onClick={this.showDrawer}>
            <span><SettingOutlined className="nav-icon mr-0" /></span>
          </Menu.Item>
       
        </Menu>
        {this.state.visible && (
          <Drawer
            title="CHA - Theme Configuration"
            placement={this.props.direction === DIR_RTL ? 'left' : 'right'}
            width={350}
            onClose={this.onClose}
            open={this.state.visible}
          >
            <ThemeConfigurator />
          </Drawer>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ theme }) => {
  const { locale } =  theme;
  return { locale }
};

export default connect(mapStateToProps)(NavPanel);
