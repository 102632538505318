import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import JobsLayout from "layouts/jobs-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, JOBS_PREFIX_PATH } from 'configs/AppConfig';
import useBodyClass from 'hooks/useBodyClass';

const AuthRoute = ({ token, type, ...rest }) => {
  if (type === 'admin' && token) {
    return <Redirect to={APP_PREFIX_PATH} />;
  } else if (!token && type !== 'admin') {
    return <Redirect to={AUTH_PREFIX_PATH} />;
  }

  return <Route {...rest} />;
};

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route {...rest} render={({ location }) => isAuthenticated ? children : (
      <Redirect to={{
        pathname: AUTH_PREFIX_PATH,
        state: { from: location }
      }} />
    )} />
  );
}

const Views = ({ locale, token, location, direction }) => {
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider
      
      locale={currentAppLocale.antd} 
      
      direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={JOBS_PREFIX_PATH}>
            <JobsLayout direction={direction} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, direction, token };
};

export default withRouter(connect(mapStateToProps)(Views));
