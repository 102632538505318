import * as lazyWelcomeImports from './general/welcome/welcomeImport';
import  * as lazyNotificationImports from './general/notification/notificationImport';
import  * as lazyChartOfAccountImports from './accounting/chartOfAccounts/chartOfAccountsImport';
// Import other category-specific imports here

const allLazyImports = {
  ...lazyWelcomeImports,
  ...lazyNotificationImports,
  ...lazyChartOfAccountImports,
  // Spread other imports here
};

export default allLazyImports;
