import { useQuery } from "react-query";
import { message } from "antd";
import { env } from "configs/EnvironmentConfig";
import isTokenExpired from "hooks/tokenEx";
import { filter } from "lodash";

// Function to fetch vouchers
const fetchNot = async (page, pageSize, filters) => {
  isTokenExpired();

  const authToken = localStorage.getItem("auth_token");
  const userId=localStorage.getItem("id");
  const headers = new Headers();
  if (authToken) {
    headers.append("Authorization", `Bearer ${authToken}`);
  }


  const response = await fetch(
    `${env.API_ENDPOINT_URL}/notification/count/unread/${userId}`,
    { headers }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();

  return { data };
};

// Custom hook
const useFetchNotication = () => {
  return useQuery(
    ["unread_notifications"],
    () => fetchNot(),
    {
      keepPreviousData: true,
      onError: (error) => {
        // Handle errors with a message
        message.error(
          error.message || "An error occurred while fetching vouchers"
        );
      },
    }
  );
};

export default useFetchNotication;
