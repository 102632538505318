import React from "react";
import { Skeleton, Table } from "antd";
import { fetchUserPermissions } from "hooks/roles";

const FeedBackTableSkeleton = () => {

  const columns = [
    { title: "Feedback", dataIndex: "name", key: "name",align: 'center'},
    { title: "Action", dataIndex: "action", key: "action",align: 'center' },
  ];

  const skeletonData = Array.from({ length: 10 }, (_, index) => ({
    key: index,
    name: (
      <Skeleton.Input style={{ width: 100 }} active size="small" />
    ),
    action: (
      <div>
        <Skeleton.Button active size="small" />
      </div>
    ),
  }));

  return (
    <Table columns={columns} dataSource={skeletonData} pagination={false} bordered/>
  );
};

export default FeedBackTableSkeleton;
