import React from 'react';
import { Layout, Form, Input, Button, Typography,Card } from 'antd';
import { LockOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Title, Text } = Typography;

const UnauthorizedPage = () => {
  const onFinish = (values) => {
    console.log('Form values:', values);
    // Perform authentication logic here
  };
  
  return (
    <Layout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Content style={{ maxWidth: '800px', padding: '10px' }}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '25px 0 0 0', fontFamily: 'sans-serif' }}>
      <Card style={{ textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.85)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '50px', width: '100%' }}>
      <h1 style={{color: '#005a87'}}>Unauthorized Access</h1>
      <p style={{color: '#333'}}>Please contact with Admin</p>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <dotlottie-player
            src="https://lottie.host/92b078c1-885b-455f-95a5-9f14f0acff1b/ll0xRwjH5w.json"
            background="transparent"
            speed="1"
            style={{ width: '800px', height: '300px' }}
            loop
            autoplay
          />
        </div>
      </Card>
    </div>
  </Content>
</Layout>
  );
};

export default UnauthorizedPage;