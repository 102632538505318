import React, { lazy, Suspense, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { JOBS_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {


  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={`${JOBS_PREFIX_PATH}/:id`} component={lazy(() => import(`./components/index`))} />        
      </Switch>
    </Suspense>
  );
};

export default AppViews;