import React, { useState } from "react";
import { Upload, message, Progress } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import { useQueryClient } from "react-query";
import { usePostContractDocument } from "hooks/humanResource/employee/contract/document/usePostDocument";
import moment from 'moment';
import { usePostCompletionTrackerDocument } from "hooks/projectManagementUnit/implementation/completionTracker/document/usePostDocument";

const generateRandomFileName = (originalFileName) => {
  let ext = originalFileName.slice(
    originalFileName.lastIndexOf(".") + 1 || Infinity
  );
  let randomDigits = "";
  for (let i = 0; i < 20; i++) {
    randomDigits += Math.floor(Math.random() * 10);
  }
  return "CompletionTrackerDocument -" + randomDigits + "." + ext;
};

const DocumentUploader = ({ taskId}) => {
  const queryClient = useQueryClient();

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false); // Define setIsSubmitting state
  const {mutate: postDocument} = usePostCompletionTrackerDocument();
  const token = localStorage.getItem("auth_token");
  const [date, setDate] = useState(moment());


  const handleFileUpload = async (file) => {
    const randomFileName = generateRandomFileName(file.name);
    setIsSubmitting(true);

    try {
      // Fetch the SAS token
      const tokenResponse = await axios.get(
        `${env.API_ENDPOINT_URL}/pmu/project/implementation/completion-tracker/document/sasuploadtoken/${randomFileName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const sasToken = tokenResponse.data.sasToken;

      // Upload the file using XMLHttpRequest
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", sasToken);

      xhr.upload.onprogress = (event) => {
        const progress = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(progress);
      };

      xhr.onload = async () => {
        setIsSubmitting(false); // Set submitting state to false immediately when loading is done

        if (xhr.status === 201) {
          // Prepare the document data
          const newDocumentData = {
            url: sasToken,
            activityCompletionTracker: taskId,
            date: date.format('YYYY-MM-DD'),
          };


          postDocument({newData:newDocumentData});
                  } else {
          message.error("Error during the upload process.");
        }

        // Reset the upload progress here, at the very end of the onload handler
        setUploadProgress(0);
      };

      xhr.onerror = () => {
        console.error("Error during the upload process.");
        message.error(`${file.name} file upload failed.`);
        setIsSubmitting(false);
        setUploadProgress(0); // Reset the upload progress on error
      };

      xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
      xhr.send(file);
    } catch (err) {
      console.error("Error: ", err);
      message.error(`${file.name} file upload failed.`);
      setIsSubmitting(false);
      setUploadProgress(0); // Reset the upload progress on error
    }
  };

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false, // Do not show the upload list
    beforeUpload: (file) => {
      handleFileUpload(file);
      return false; // prevent default upload behavior
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setUploadProgress(0); // Reset progress on success
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setUploadProgress(0); // Reset progress on error
      }
    },
    onRemove: () => {
      setUploadProgress(0); // Reset progress on remove
    },
  };

  return (
    <div>
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Upload.Dragger>
      {uploadProgress > 0 && <Progress percent={uploadProgress} />}
    </div>
  );
};

export default DocumentUploader;
