import antdFrFR from 'antd/es/locale/fr_FR';
import frMsg from "../locales/fr_FR.json";

const FrLang = {
  antd: antdFrFR,
  locale: 'TR',
  messages: {
    ...frMsg,
    "menu.users_management": "Users Management",
    "menu.roles": "Roles",
    "menu.users": "Users",
    "menu.project_management_unit": "Project Management Unit",
    "menu.opportunity": "Opportunity",
    "menu.project_management": "Project Management",
    "menu.configurations_pmu": "Configurations",
    "menu.sector": "Sector",
    "menu.donors": "Donors",
    "menu.people_targeted_categories": "People Targeted Categories",
    "menu.accounting": "Accounting",
    "menu.chart_of_accounts": "Chart of Accounts",
    "menu.vouchers": "Vouchers",
    "menu.journal": "Journal",
    "menu.ledger": "Ledger",
    "menu.trial_balance": "Trial Balance",
    "menu.payroll_voucher_admin": "Payroll Voucher Admin",
    "menu.exchange_rates": "Exchange Rates",
    "menu.financial_reports": "Financial Reports",
    "menu.balance_sheet": "Balance Sheet",
    "menu.income_expense_report": "Income & Expense Report",
    "menu.notes": "Notes",
    "menu.configurations_accounting": "Accounting Configurations",
    "menu.setting_financial_year": "Setting Financial Year",
    "menu.gain_loss_account": "Gain/Loss Account",
    "menu.office": "Office",
    "menu.currency": "Currency",
    "menu.bank": "Bank",
    "menu.bank_account": "Bank Account",
    "menu.supplier": "Supplier",
    "menu.voucher_signature": "Voucher Signature",
    "menu.human_resources": "Human Resources",
    "menu.prospective_employee": "Prospective Employee",
    "menu.employee": "Employee",
    "menu.attendance": "Attendance",
    "menu.payroll": "Payroll",
    "menu.payroll_exchange_rate": "Payroll Exchange Rate",
    "menu.recruitment": "Recruitment",
    "menu.configurations_hr": "HR Configurations",
    "menu.employee_grade": "Employee Grade",
    "menu.designation": "Designation",
    "menu.profession": "Profession",
    "menu.field_of_study": "Field of Study",
    "menu.degree": "Degree",
    "menu.document_type": "Document Type",
    "menu.leave": "Leave",
    "menu.working_hours": "Working Hours",
    "menu.category": "Category",
    "menu.employment_type": "Employment Type",
    "menu.procurement": "Procurement",
    "menu.vendors": "Vendors",
    "menu.configurations_procurement": "Procurement Configurations",
    "menu.vendor_category": "Vendor Category",
    "menu.document_type": "Document Type",
    "menu.inventory": "Inventory",
    "menu.consumable_items": "Consumable Items",
    "menu.master_store_item": "Master Store Item",
    "menu.store_items": "Store Items",
    "menu.expendable_item": "Expendable Item",
    "menu.non_expendable_item": "Non-Expendable Item",
    "menu.general_inventory_items": "General Inventory Items",
    "menu.configurations_inventory": "Inventory Configurations",
    "menu.unit": "Unit",
    "menu.asset_type": "Asset Type",
    "menu.receipt_type": "Receipt Type",
    "menu.depreciation": "Depreciation",
    "menu.status": "Status",
    "menu.supply_chain": "Supply Chain",
    "menu.lots": "Lots",
    "menu.shipments": "Shipments",
    "menu.packaging": "Packaging",
    "menu.distribution": "Distribution",
    "menu.sales": "Sales",
    "menu.configurations_supply_chain": "Supply Chain Configurations",
    "menu.items": "Items",
    "menu.grade": "Grade",
    "menu.supplier": "Supplier",
    "menu.system_configuration": "System Configuration",
    "menu.languages": "languages"
  },
};
export default FrLang;
