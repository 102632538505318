
import {
    Divider,
    Table,
    Button,
    Space,
    Modal

} from "antd";
import React, { useState, useEffect } from "react";
import { LoadingOutlined, CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useRemoveCategory } from "hooks/humanResource/configPanel/category/useRemoveCategory";
import { usePutResolved } from "hooks/projectManagementUnit/implementation/completionTracker/feedBack/usePutResolved";


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const FeedBackTable = ({
    data,
    isError,
    permissions,
   }) => {

    const { mutate } = usePutResolved();
   

    const columns = [
        
        {
            title: 'Feedback',
            dataIndex: 'feedback',
            key: 'feedback',
            align: 'center',
            render: (_, record) => 
              record.isResolved 
                ? <span><strong>(Resolved) {record.givenByEmail} </strong>: {record.feedback}<br /><br /><strong>Resolved By: {record.resolvedByEmail}</strong></span>
                : <span><strong>(Not Resolved) {record.givenByEmail}</strong>: {record.feedback}</span>
          },
       
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (_, record) => {

                return (
                    <Space>
                         {permissions.includes("resolveProjectProgressTrackerTaskCompletionFeedback") && (
                    <Button
                      icon={<CheckCircleOutlined />}
                      disabled={record.isResolved}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleResolve(record);
                      }}
                      style={{ color: record.isResolved ? 'black' : "MediumSeaGreen" }}
                    />
                  )}
                        

                    </Space>
                );
            },
        }
    ];

    const handleResolve = (record) => {
        Modal.confirm({
          title:"Are you sure to resolve this feedback?",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk: () => {
            mutate({ id: record._id });
          },
          maskClosable: true,
        });
      };


    if (isError) {
        return <div>Something went wrong</div>;
    }

    return (
       
        <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={data => data._id}
        pagination={false}
        scroll={{ x: 'max-content' }}
        bordered
      />
   
    </>

    );
};

export default FeedBackTable;