import React,{useState,useEffect} from "react";
import { CheckOutlined, GlobalOutlined, DownOutlined  } from '@ant-design/icons';
import { Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import { onLocaleChange } from 'redux/actions/Theme'
import { env } from "configs/EnvironmentConfig";


export const NavLanguage = ({ locale, configDisplay, onLocaleChange }) => {

		
	
	const [data, setData] = useState([]);
	const token=localStorage.getItem("auth_token");
	const fetchAllLanguages = async () => {
		try {
		  const response = await fetch(`${env.API_ENDPOINT_URL}/system-language?page=${1}&pageSize=${50}`, {
			method: 'GET',
			headers: {
			  "Content-Type": 'application/json',
			  "Authorization": `Bearer ${token}`
			},
		  });
		  if (response.ok) {
			const dataResult = await response.json();
			setData(dataResult.languages);

		  } else {
	
		  }
		} catch (error) {
		  console.error(error);
		}
	  };
	  useEffect(()=>{
		fetchAllLanguages();
	  },[]);
	  useEffect(() => {
		const storedLocale = localStorage.getItem('locale');
		if (storedLocale !== JSON.stringify(locale)) {
		  localStorage.setItem('locale', JSON.stringify(locale));
		  // Conditional reload
		
			window.location.reload();
		  
		}
	  }, [locale]);
	
	const languageOption = (
		<Menu>
			{
				
				data.map((elm, i) => {return (
					<Menu.Item 
						key={i} 
						className={locale === elm.abbreviation? 'ant-dropdown-menu-item-active': ''} 
						onClick={() => onLocaleChange(elm.abbreviation)}
					>
						<span className="d-flex justify-content-between align-items-center">
							<div>
								<span className="font-weight-normal ml-2">{elm.abbreviation}</span>
							</div>
							{locale === elm.abbreviation? <CheckOutlined className="text-success" /> : null}
						</span>
					</Menu.Item>
				)})
			}
		</Menu>
	)
	return (
		<Dropdown placement="bottomRight" overlay={languageOption} trigger={["click"]}>
			{
				configDisplay ?
				(
					<a href="#/" className="text-gray" onClick={e => e.preventDefault()}>
						
					
						
					</a>
				)
				:
				(
					<Menu mode="horizontal">
						<Menu.Item key="language">
							<a href="#/" onClick={e => e.preventDefault()}>
								<GlobalOutlined className="nav-icon mr-0" />
							</a>
						</Menu.Item>
					</Menu>
				)
			}
		</Dropdown>
	)
}

const mapStateToProps = ({ theme }) => {
  const { locale } =  theme;
  return { locale }
};

export default connect(mapStateToProps, {onLocaleChange})(NavLanguage);
