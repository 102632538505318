import {
  Space,
    Card,
    Button,
    Drawer,
    Form,
    Descriptions,
    Col,
    Row,
    Popconfirm,
    Tag,message

} from "antd";
import FileUploadDragger from "./document/upload";
import React, { useState, useEffect } from "react";
import { LoadingOutlined, EditOutlined, DeleteTwoTone } from "@ant-design/icons";
import { usePostTechnicalQuestion } from "hooks/humanResource/recruitment/interview/technicalQuestion/usePostQuestion";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { usePostCategory } from "hooks/humanResource/configPanel/category/usePostCategory";
import { useFetchProjectManagmentDistrict } from "hooks/projectManagementUnit/implementation/taskcompletion/useFetchProjectManagmentDistrict";
import { usePostTaskCompletion } from "hooks/projectManagementUnit/implementation/taskcompletion/usePostTaskCompletion";
import { useFetchCompletionTracker } from "hooks/projectManagementUnit/implementation/completionTracker/useFetchCompletionTracker";
import { usePutSubmitForReview } from "hooks/projectManagementUnit/implementation/completionTracker/usePutSubmitForReview";
import { usePutForApprov } from "hooks/projectManagementUnit/implementation/completionTracker/usePutForApprov";
import { useQueryClient } from "react-query";
import { useFetchCompletionTrackerDocument } from "hooks/projectManagementUnit/implementation/completionTracker/document/useFetchDocument";
import CompletionTrackerTableSkeleton from "./document/tableSkeleton";
import CompletionTrackerDocumentTable from "./document/documentTable";
import DocumentViewer from "./document/documentViewer";
import useFetchDownloadDocumentCompletionTracker from "hooks/projectManagementUnit/implementation/completionTracker/document/useFetchDocumentDownload";
import { useRemoveCategory } from "hooks/humanResource/configPanel/category/useRemoveCategory";
import { useRemoveCompletionTrackerDocumsnt } from "hooks/projectManagementUnit/implementation/completionTracker/document/useRemoveDocument";
import FeedBackModal from "./feedBack/feedBack";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const CompletionTrackerDrawer = ({ 
    isDrawerVisible,
    handleDrawerCancel,
    permissions,
    id
    
 }) => {
  const roundBorderStyles = {
    borderRadius: "16px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    
    marginBottom: "16px", 
  };

  const cardBodyStyles = {
    padding: "1.5rem",
  };
    const queryClient = useQueryClient();
    const [selectedFile, setSelectedFile] = useState(null);
    const [downloadFilename, setDownloadFilename] = useState(null);
    const [isModalVisibleFeed, setIsModalVisibleFeed] = useState(false);
    // Fetch SAS token when downloadFilename changes
    const { data: sasToken, isSuccess } =
    useFetchDownloadDocumentCompletionTracker(downloadFilename);
    const {data:documentsData,isLoading:loadingDocuments, isError:isErrorDocuments} = useFetchCompletionTrackerDocument(id);
    const {data,isLoading:loading, isError} = useFetchCompletionTracker({id:id});
    const {mutate:review} = usePutSubmitForReview();
    const {mutate:approve} = usePutForApprov();
    const {mutate:deleteDocument} = useRemoveCompletionTrackerDocumsnt();
    const handleView = (record) => {
      setSelectedFile(record.name);
    };
    const handleDownload = (record) => {
      setDownloadFilename(record.name);
    
    };
    const handleRemove = (record) => {
      deleteDocument({id:record._id});
    };

    useEffect(() => {
      if (isSuccess && sasToken) {
        const newWindow = window.open(sasToken, "_blank");
        if (newWindow) newWindow.opener = null;
        else message.error("Unable to open the file.");

   setDownloadFilename(null); // Reset the download trigger
 }
}, [sasToken, isSuccess, downloadFilename]);

    return (
        <div> 
      <Drawer
        title="Activity Completion Tracker Details"
        width={800}
        onClose={handleDrawerCancel}
        open={isDrawerVisible}
        bodyStyle={{
        paddingBottom: 80,
        }}
       
        >
        <div  className="container" style={{paddingTop: 30,width:"100%" }}  >
        <Row gutter={16}>
            <Col xs={24}>
                <Card loading={loading}
                style={roundBorderStyles} bodyStyle={cardBodyStyles}
                >
                    <Row>
                      <Space>
                     {permissions.includes('viewProjectProgressTrackerTaskCompletionFeedback') && (
                       <Button style={{backgroundColor:'darkblue',color:'white'}} 
                       onClick={()=>setIsModalVisibleFeed(true)}
                       >View Feedback</Button>
                       )}
                      {data?.status==="Pending Administration" || data?.status==="Revisions Required" ? (<Popconfirm
                title="Are you sure to submit the activity completion tracker for review?"
                okText="Yes"
                cancelText="No"
                onConfirm={()=>{review({id:id},
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries('taskCompletion');
                    },
                    onError: (error) => {
                      error("Apologies, an error has occurred. Please try again later.");
                      // Additional error logic
                    },
                  }
                )}}
            
              >
                {permissions.includes('submitProjectProgressTrackerTaskCompletionForReview') && (<Button style={{backgroundColor:'darkorange',color:'white'}} >Submit for review</Button>)}
              </Popconfirm>) : ""}
                      {data?.status==="Under Review" && permissions.includes('approveProjectProgressTrackerTaskCompletion') ? (<Popconfirm
                title="Are you sure to approve this activity completion tracker?"
                okText="Yes"
                cancelText="No"
                onConfirm={()=>{approve({id:id},
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries('taskCompletion');
                    },
                    onError: (error) => {
                      error("Apologies, an error has occurred. Please try again later.");
                      // Additional error logic
                    },
                  }
                )}}
            
              >
                <Button style={{backgroundColor:'green',color:'white'}} >Approve</Button>
              </Popconfirm>) : ""}
                   
                  
                   </Space>
                   </Row>
                   <br/>
                   <br/>
                    <Descriptions bordered loading={loading}>
                         <Descriptions.Item label="Project" span={3}>{data?.project}</Descriptions.Item>
                        <Descriptions.Item label="Province" span={3}>{data?.province}</Descriptions.Item>
                        <Descriptions.Item label="Location (City/District)" span={3}>{data?.projectLocations?.name}</Descriptions.Item>
                        <Descriptions.Item label="Activity" span={3}>{data?.activity}</Descriptions.Item>
                        <Descriptions.Item label="Sub Activity" span={3}>{data?.subActivity}</Descriptions.Item>
                        <Descriptions.Item label="Date" span={3}>{moment(data?.startDate).format('YYYY/MM/DD')+'-'+moment(data?.endDate).format('YYYY/MM/DD')}</Descriptions.Item>

                        <Descriptions.Item label="Completed Target" span={1.5}>{data?.completedTarget}</Descriptions.Item>
                        <Descriptions.Item label="Status" span={1.5}>{<Tag color={ data?.status === 'Pending Administration' ? 'orange' : data?.status === 'Under Review' ? 'blue' : data?.status === 'Revisions Required' ? 'yellow':  data?.status === 'Approved' ? 'green':'red'}>
                          {data?.status}      
                        </Tag>}</Descriptions.Item>
                        <Descriptions.Item label="Notes" span={3}>{data?.notes}</Descriptions.Item>
                        {data?.status!=="Approved" ? "" : (<>        
                        <Descriptions.Item label="Approved By" span={1.5}>{data && data?.approvedByEmail}</Descriptions.Item>
                        <Descriptions.Item label="Approved Date" span={1.5}>{data && moment(data?.approvedByDate).format('YYYY/MM/DD')}</Descriptions.Item> 
                        </>)}
                    </Descriptions>

                </Card>

              
            </Col>
        </Row>
        <Row gutter={16}>

        </Row>
       {permissions.includes('addProjectProgressTrackerTaskCompletionDetailsDocument') && ( <div>
        <FileUploadDragger taskId={id} />
        </div>
        )}
        {loadingDocuments ? <CompletionTrackerTableSkeleton /> : <CompletionTrackerDocumentTable
         documents={documentsData}
         onView={handleView}
         onDownload={handleDownload} 
         onRemove={handleRemove}/>}
        <DocumentViewer
            filename={selectedFile}
            onClose={() => setSelectedFile(null)}
          />
      </div>
        </Drawer>

        {id && isModalVisibleFeed && (
          <FeedBackModal
          isModalVisible={isModalVisibleFeed}
          handleCancel={()=>setIsModalVisibleFeed(false)}
          id={id}
          permissions={permissions}
          />
        )}
        </div>
    );
};

export default CompletionTrackerDrawer;