// firebase.js
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCA6flwaivtZfgT0S7o1T30DJEfJSUCX3Q",
  authDomain: "cha-erp.firebaseapp.com",
  projectId: "cha-erp",
  storageBucket: "cha-erp.appspot.com",
  messagingSenderId: "122028440153",
  appId: "1:122028440153:web:c758860d8da709e3278f48",
  measurementId: "G-RRXK6WB9SZ"
};

const app = initializeApp(firebaseConfig);

export const requestPermission = async () => {
  try {
    console.log('Checking if Firebase messaging is supported...');
    const messagingSupported = await isSupported();

    if (!messagingSupported) {
      console.log("Firebase messaging is not supported on this browser.");
      return null;
    }

    const messaging = getMessaging(app);
    
    console.log('Requesting user permission for notifications...');
    if (!('Notification' in window)) {
      console.log("This browser does not support notifications.");
      return null;
    }

    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.log("Notification permission not granted.");
      return null;
    }

    console.log("Notification permission granted.");
    const currentToken = await getToken(messaging, {
      vapidKey: "BGGVeCcNVFuqF3DtoX5l2xbwVWJECaI_aJi68fc1YS4coCezh6V1eknp86sOxPRDsmcEDbQEwAvvT96VIhw7epI"
    });

    if (!currentToken) {
      console.log("No registration token available. Request permission to generate one.");
      return null;
    }

    console.log("Client Token: ", currentToken);
    return currentToken;
  } catch (error) {
    console.error("Error occurred during the process", error);
    return null;
  }
};

export { getMessaging, onMessage };
