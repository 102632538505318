import React, { useState, useEffect } from "react";
import { Modal, Spin, Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import axios from "axios";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { env } from "configs/EnvironmentConfig";

const DocumentViewer = ({ filename, onClose }) => {
  const token = localStorage.getItem("auth_token");
  const [isLoading, setIsLoading] = useState(true);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [isPdf, setIsPdf] = useState(false);
  const [extension, setExtension] = useState('');


  const fetchDocumentUrl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${env.API_ENDPOINT_URL}/pmu/project/implementation/completion-tracker/document/sasuploadtoken/${filename}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDocumentUrl(response.data.sasToken);
      const dotIndex = filename.lastIndexOf(".");
      const extension = filename.substring(dotIndex + 1);
      setExtension(extension);



    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filename) {
      fetchDocumentUrl();
    }
  }, [filename]);


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const zoomIn = () => {
    setScale(scale + 0.1);
  };

  const zoomOut = () => {
    setScale(scale - 0.1);
  };


  const openImageInNewTab = () => {
    window.open(documentUrl, "_blank");
  };


  return (

    <Modal
    open={!!filename}
    onCancel={onClose}
    width="80%"
    footer={null}
    style={{ top: 20 }}
  >
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "fixed",
          zIndex: 1000,
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#fff",
          padding: "5px",
          borderRadius: "4px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Button
          onClick={zoomIn}
          icon={<PlusOutlined />}
          style={{ marginRight: "10px" }}
        />
        <Button onClick={zoomOut} icon={<MinusOutlined />} />
      </div>
      {isLoading ? (
        <Spin size="large" style={{ marginTop: "50px" }} />
      ) : extension  === "pdf" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "50px",
          }}
        >
          <Document file={documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={scale}
              />
            ))}
          </Document>
        </div>
      ) : 
      <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "50px",
            }}
          >
            <Button onClick={openImageInNewTab}>Open Image in New Tab</Button>
          </div>
    }
    </div>
  </Modal>
    // <>{extension  === "pdf" ? (
    //   <Modal
    //   open={!!filename}
    //   onCancel={onClose}
    //   width="80%"
    //   footer={null}
    //   style={{ top: 20 }}
    // >
    //   <div style={{ position: "relative" }}>
    //     <div
    //       style={{
    //         position: "fixed",
    //         zIndex: 1000,
    //         top: "10px",
    //         left: "50%",
    //         transform: "translateX(-50%)",
    //         backgroundColor: "#fff",
    //         padding: "5px",
    //         borderRadius: "4px",
    //         boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    //       }}
    //     >
    //       <Button
    //         onClick={zoomIn}
    //         icon={<PlusOutlined />}
    //         style={{ marginRight: "10px" }}
    //       />
    //       <Button onClick={zoomOut} icon={<MinusOutlined />} />
    //     </div>
    //     {isLoading ? (
    //       <Spin size="large" style={{ marginTop: "50px" }} />
    //     ) : (
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           paddingTop: "50px",
    //         }}
    //       >
    //         <Document file={documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
    //           {Array.from(new Array(numPages), (el, index) => (
    //             <Page
    //               key={`page_${index + 1}`}
    //               pageNumber={index + 1}
    //               scale={scale}
    //             />
    //           ))}
    //         </Document>
    //       </div>
    //     )}
    //   </div>
    // </Modal>
    // ) : (
    //   <Button onClick={openImageInNewTab}>Open Image</Button>
    // )

    // }
    // </>
   
  );
};

export default DocumentViewer;
