// Notification.js
import React, { useEffect } from "react";
import { notification } from "antd";
import { requestPermission, getMessaging, onMessage } from "./firebase";

const NotificationComponent = ({ fetchSpecificNotification }) => {
  useEffect(() => {
    const initializeNotification = async () => {
      const token = await requestPermission();
      if (!token) return;

      const messaging = getMessaging();

      onMessage(messaging, (payload) => {
        notification.open({
          message: payload.notification.title,
          description: payload.notification.body,
          duration: 1.5,
        });

        if (typeof fetchSpecificNotification === 'function') {
          fetchSpecificNotification();
        } else {
          console.error('fetchSpecificNotification is not a function', fetchSpecificNotification);
        }
      });
    };

    initializeNotification();
  }, [fetchSpecificNotification]);

  return <div></div>;
}

export default NotificationComponent;
