import { useQuery } from "react-query";
import axios from "axios";
import { message } from "antd";
import { env } from "configs/EnvironmentConfig";
import isTokenExpired from "hooks/tokenEx";

const useFetchDownloadDocumentCompletionTracker = (filename) => {
  isTokenExpired();

  const token = localStorage.getItem("auth_token");

  const fetchSasToken = async () => {
    if (!filename) {
      throw new Error("Filename is required.");
    }
    try {
      const response = await axios.get(
        `${env.API_ENDPOINT_URL}/pmu/project/implementation/completion-tracker/document/sasuploadtoken/${filename}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.sasToken;
    } catch (error) {
      // Axios throws error, you can handle response specific errors here if needed
      throw new Error(
        error.response?.data?.message || "Error fetching SAS token"
      );
    }
  };

  return useQuery(["sasToken", filename], fetchSasToken, {
    enabled: !!filename,
    onError: (error) => {
      message.error(error.message || "An error occurred");
    },
  });
};

export default useFetchDownloadDocumentCompletionTracker;
