import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Button,message } from 'antd';
import { 
  MailOutlined, 
  BellOutlined, 
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import notificationData from "assets/data/notification.data.json";
import useFetchNotifications from 'hooks/notifications/useFetchNotifications';
import Flex from 'components/shared-components/Flex'
import { env } from 'configs/EnvironmentConfig';
import { NavLink, useHistory, useParams } from "react-router-dom";
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { fetchUserPermissions } from 'hooks/roles';
import Notification from 'hooks/notification';
import ActivityDrwer from '../../views/app-views/admin/notificationCenter/drawer/completionTrackerDrawer';

const getIcon =  icon => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <MailOutlined />;
  }
}


export const NavNotification = () => {
  const permissions=fetchUserPermissions();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState('');
  const token=localStorage.getItem("auth_token");
  const userId=localStorage.getItem("id");
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [dataDrawer, setDrawerData] = useState([]);
  const [drawerloading, setdrawerLoading] = useState(false);
  const [taskId,setTaskId]=useState('');
  const {data:notiData,isLoading,refetch: notificationFetch}=useFetchNotifications();

  // useEffect(() => {
  //   if(notiData){
  //     setData(notiData?.data.unreadNotifications);
  //     setCount(notiData?.data.unreadCount);
  //   }
  // },[notiData])
  const fetchSpecificNotification = async () => {
    notificationFetch();
   
  };

  const fetchData = async (idTaskC) => {
    try {
      setdrawerLoading(true)
      const response = await fetch(`${env.API_ENDPOINT_URL}/pmu/project/activity-completion-tracker/notification-details/${idTaskC}`, {
        method: 'GET',
        headers: {
          "Content-Type": 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      if (response.ok) {
        const data = await response.json();
        setDrawerData(data)
  
        setdrawerLoading(false);
  
      } else {

      }
    } catch (error) {
      console.error(error);
      message.error('An error occurred while fetching the data.');
    }
  }

  const handleTaskDocumentClick = (record) => {
    setIsDrawerVisible(true);
   fetchData(record.itemId);
   setTaskId(record.itemId)
   setVisible(false);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  const readSpecificNotification=(notificationId)=>{
    fetch(`${env.API_ENDPOINT_URL}/notification/${notificationId}/read`,{
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${token}`
  
      }
  })
  .then(res=>{
      if(res.status!==200 && res.status !==201){
          //throw new Error("failed");
          message.warn('Disconnected!');
      }
      return res.json();})
      .then(result => {
        
        
          fetchSpecificNotification();
             
          
      })
      .catch(e => {
          console.log(e)
      })
  };
  const readAllNotification=()=>{
    fetch(`${env.API_ENDPOINT_URL}/notification/${userId}/readall`,{
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${token}`
  
      }
  })
  .then(res=>{
      if(res.status!==200 && res.status !==201){
          //throw new Error("failed");
          message.warn('Disconnected!');
      }
      return res.json();})
      .then(result => {
        
        fetchSpecificNotification();
         
                  
          
      })
      .catch(e => {
          console.log(e)
      })
  };
  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button className="text-primary" type="link" onClick={() => readAllNotification()} size="small">Read All </Button>
      </div>
      <div className="nav-notification-body" >
        {/* {getNotificationBody(data)} */}
        { notiData?.data.unreadNotifications.length > 0 ?
  <List
    size="small"
    itemLayout="horizontal"
    dataSource={notiData?.data.unreadNotifications}
    renderItem={item => (
      <List.Item className="list" >
        <Flex alignItems="center">
          <div className="pr-3">
            {item.img? <Avatar src={`/img/avatars/${item.img}`} /> : <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />}
          </div>
          <div className="mr-3" onClick={()=>readSpecificNotification(item._id)}>
            <span className="font-weight-bold text-dark"><a onClick={()=>handleTaskDocumentClick(item)}>{`Activity Completion Tracker (${item.projectCode})`}</a> </span>
            <span className="text-gray-light">{item.messageTitle}</span>
          </div>
          {/* <small className="ml-auto">{item.time}</small> */}
        </Flex>
      </List.Item>
    )}
  />
  :
  <div className="empty-notification">
    <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
    <p className="mt-3">You have viewed all notifications</p>
    <Button onClick={()=>setVisible(false)}><NavLink to={`${APP_PREFIX_PATH}/notifcations`} className="d-block">Notification Center</NavLink></Button>
  </div>}
      </div>
      {
        notiData?.data.unreadNotifications.length > 0 ? 
        <div className="nav-notification-footer">
         {permissions.includes('viewNotification') && (  <NavLink to={`${APP_PREFIX_PATH}/notifcations`} className="d-block">View all</NavLink>)}
        </div>
        :
        null
      }
    </div>
  );
  useEffect(()=>{
    fetchSpecificNotification();
  },[]);
  return (
    <>
    <Notification 
    fetchSpecificNotification={fetchSpecificNotification}
     />
    <Dropdown 
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={notiData?.data.unreadCount}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>

    {isDrawerVisible && (
      <ActivityDrwer 
        id={taskId}
        permissions={permissions}
        isDrawerVisible={isDrawerVisible}
        handleDrawerCancel={()=>setIsDrawerVisible(false)}
      />
      )}
    </>
  )
}


export default NavNotification;
