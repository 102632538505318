
// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Menu, Grid } from "antd";
// import IntlMessage from "../util-components/IntlMessage";
// import { FormattedMessage } from "react-intl";
// import Icon from "../util-components/Icon";
// import navigationConfig from "configs/NavigationConfig";
// import { connect } from "react-redux";
// import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
// import utils from 'utils';
// import { onMobileNavToggle } from "redux/actions/Theme";

// const { SubMenu } = Menu;
// const { useBreakpoint } = Grid;

// const setLocale = (isLocaleOn, localeKey) =>
//     isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();


// const setDefaultOpen = (key) => {
//   let keyList = [];
//   let keyString = "";
//   if (key) {
//     const arr = key.split("-");
//     for (let index = 0; index < arr.length; index++) {
//       const elm = arr[index];
//       index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
//       keyList.push(keyString);
//     }
//   }
//   return keyList;
// };

// const SideNavContent = (props) => {
//   const { sideNavTheme, routeInfo, hideGroupTitle, onMobileNavToggle, localization } = props;
//   const screens = useBreakpoint();
//   const isMobile = !screens.lg;

 
//   const closeMobileNav = () => {
//     if (isMobile) {
//       console.log("Closing mobile nav");
//       onMobileNavToggle(false);
//     }
//   };

//   useEffect(() => {
//     if (navigationConfig.length < 1) {
//       window.location.reload();
//     }
//   }, []);

//   return (
//     <Menu
//       theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
//       mode="inline"
//       style={{ height: "100%", borderRight: 0 }}
//       defaultSelectedKeys={[routeInfo?.key]}
//       defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
//       className={hideGroupTitle ? "hide-group-title" : ""}
//     >
//       {navigationConfig.map((menu) =>
//         menu.submenu?.length > 0 ? (
//           <Menu.ItemGroup
//             key={menu.key}
//             title={<FormattedMessage id={menu.title} />}
//           >
//             {menu.submenu.map((subMenuFirst) =>
//               subMenuFirst.submenu?.length > 0 ? (
//                 <SubMenu
//                   icon={subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
//                   key={subMenuFirst.key}
//                   title={<FormattedMessage id={subMenuFirst.title} />}
//                 >
//                   {subMenuFirst.submenu.map((subMenuSecond) => (
//                     <Menu.Item key={subMenuSecond.key}>
//                       {subMenuSecond.icon ? <Icon type={subMenuSecond.icon} /> : null}
//                       <span> <FormattedMessage id={subMenuSecond.title} /></span>
//                       <Link to={subMenuSecond.path} onClick={closeMobileNav} />
//                     </Menu.Item>
//                   ))}
//                 </SubMenu>
//               ) : (
//                 <Menu.Item key={subMenuFirst.key}>
//                   {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
//                   <span>{<FormattedMessage id={subMenuFirst.title} />}</span>
//                   <Link to={subMenuFirst.path} onClick={closeMobileNav} />
//                 </Menu.Item>
//               )
//             )}
//           </Menu.ItemGroup>
//         ) : (
//           <Menu.Item key={menu.key}>
//             {menu.icon ? <Icon type={menu.icon} /> : null}
//             {/* <span><FormattedMessage id={menu.title} /></span> */}
//             {menu.path ? <Link to={menu.path} onClick={closeMobileNav} /> : null}
//           </Menu.Item>
//         )
//       )}
//     </Menu>
//   );
// };

// const TopNavContent = (props) => {
//   const { topNavColor, localization } = props;
//   return (
//     <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
//       {navigationConfig.map((menu) =>
//         menu.submenu?.length > 0 ? (
//           <SubMenu
//             key={menu.key}
//             popupClassName="top-nav-menu"
//             title={
//               <span>
//                 {menu.icon ? <Icon type={menu?.icon} /> : null}
//                 <span>{<FormattedMessage id={menu.title} />}</span>
//               </span>
//             }
//           >
//             {menu.submenu.map((subMenuFirst) =>
//               subMenuFirst?.submenu?.length > 0 ? (
//                 <SubMenu
//                   key={subMenuFirst.key}
//                   popupClassName="top-nav-menu"
//                   icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
//                   title={<FormattedMessage id={subMenuFirst.title} />}
//                 >
//                   {subMenuFirst.submenu.map((subMenuSecond) => (
//                     <Menu.Item key={subMenuSecond.key}>
//                       <span><FormattedMessage id={subMenuSecond.title} /></span>
//                       <Link to={subMenuSecond.path} />
//                     </Menu.Item>
//                   ))}
//                 </SubMenu>
//               ) : (
//                 <Menu.Item key={subMenuFirst.key}>
//                   {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
//                   <span>{<FormattedMessage id={subMenuFirst.title} />}</span>
//                   <Link to={subMenuFirst.path} />
//                 </Menu.Item>
//               )
//             )}
//           </SubMenu>
//         ) : (
//           <Menu.Item key={menu.key}>
//             {menu.icon ? <Icon type={menu?.icon} /> : null}
//             <span>{<FormattedMessage id={menu.title} />}</span>
//             {menu.path ? <Link to={menu.path} /> : null}
//           </Menu.Item>
//         )
//       )}
//     </Menu>
//   );
// };

// const MenuContent = (props) => {
//   return props.type === NAV_TYPE_SIDE ? (
//     <SideNavContent {...props} />
//   ) : (
//     <TopNavContent {...props} />
//   );
// };

// const mapStateToProps = ({ theme }) => {
//   const { sideNavTheme, topNavColor } = theme;
//   return { sideNavTheme, topNavColor };
// };

// export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import { FormattedMessage } from "react-intl";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;
let isRole=localStorage.getItem("role");
const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
  

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};


// const SideNavContent = (props) => {
// 	const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
// 	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
// 	const closeMobileNav = () => {
// 		if (isMobile) {
// 			onMobileNavToggle(false)
// 		}
// 	}
//   useEffect(() => {
//     if(navigationConfig.length < 1) {
      
//       window.location.reload();
//     }
//   }, []);
//   return (
//     <Menu
//       theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
//       mode="inline"
//       style={{ height: "100%", borderRight: 0 }}
//       defaultSelectedKeys={[routeInfo?.key]}
//       defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
//       className={hideGroupTitle ? "hide-group-title" : ""}
//     >
//       {navigationConfig.map((menu) =>
//         menu.submenu?.length > 0  ? (
         
//           <Menu.ItemGroup
//             key={menu.key}
//             title={ menu.title}
//           >
//             {menu.submenu.map((subMenuFirst) =>
//               subMenuFirst.submenu?.length > 0  ? (
//                 <SubMenu
//                   icon={
//                     subMenuFirst.icon ? (
//                       <Icon type={subMenuFirst?.icon} />
//                     ) : null
//                   }
//                   key={subMenuFirst.key}
//                   title={subMenuFirst.title }
//                 >
//                   {subMenuFirst.submenu.map((subMenuSecond) => (
//                     <Menu.Item key={subMenuSecond.key}>
//                       {subMenuSecond.icon ? (
//                         <Icon type={subMenuSecond?.icon} />
//                       ) : null}
//                       <span>
//                         { subMenuSecond.title }
//                       </span>
//                       <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
//                     </Menu.Item>
//                   ))}
//                 </SubMenu>
               
//               ) : (
                
//                 <Menu.Item key={subMenuFirst.key}>
//                 { (subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null)}
//                 <span>{ subMenuFirst.title }</span>
//                 <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
//               </Menu.Item>

                              
                
//               )
//             )}
//           </Menu.ItemGroup>
         
//         ) : (
//           <Menu.Item key={menu.key}>
//             {menu.icon ? <Icon type={menu?.icon} /> : null}
//             <span>{ menu?.title}</span>
//             {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
//           </Menu.Item>
          
//         )
//       )}
//     </Menu>
//   );
// };

const SideNavContent = (props) => {
  const { sideNavTheme, routeInfo, hideGroupTitle, onMobileNavToggle } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  useEffect(() => {
    if (navigationConfig.length < 1) {
      window.location.reload();
    }
  }, []);

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu) =>
        menu.submenu?.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={<FormattedMessage id={menu.title} />}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu?.length > 0 ? (
                <SubMenu
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  key={subMenuFirst.key}
                  title={<FormattedMessage id={subMenuFirst.title} />}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? <Icon type={subMenuSecond.icon} /> : null}
                      <span>
                        <FormattedMessage id={subMenuSecond.title} />
                      </span>
                      <Link to={subMenuSecond.path} onClick={closeMobileNav} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>
                    <FormattedMessage id={subMenuFirst.title} />
                  </span>
                  <Link to={subMenuFirst.path} onClick={closeMobileNav} />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu.icon} /> : null}
            <span>
              {/* <FormattedMessage id={menu.title} /> */}
            </span>
            {menu.path ? <Link to={menu.path} onClick={closeMobileNav} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
}

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu?.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{<FormattedMessage id={menu.title} />}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst?.submenu?.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={<FormattedMessage id={subMenuFirst.title} />}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                      <FormattedMessage id={subMenuSecond.title} />
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{<FormattedMessage id={subMenuFirst.title} />}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>
              {/* {menu?.title} */}
              {/* {setLocale(localization, menu?.title)} */}
            
            </span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);