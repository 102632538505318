import UnauthorizedPage from '../../admin/unathorized';
import {Route} from "react-router-dom";

const ProtectedRoute = ({ component: Component, userPermissions, allowedPermissions, userRole ,...rest }) => {
    const isAuthorized = userPermissions.some(permission => allowedPermissions.includes(permission));
   
    return (
      <Route
        {...rest}
        render={props =>
          isAuthorized ? (
            <Component {...props} userRole={userRole}  />
          ) : (
           <UnauthorizedPage/>
          )
        }
      />
    );
  };

  export default ProtectedRoute;