import enLang from './entries/en_US';
import drLang from './entries/dr';
import psLang from './entries/ps';
import trLang from './entries/tr'

const AppLocale = {
    EN: enLang,
    DR: drLang,
    PS: psLang,
    TR: trLang
};

export default AppLocale;