import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { env } from "configs/EnvironmentConfig";
import isTokenExpired from "hooks/tokenEx";

const removeCategory = async (id) => {
    isTokenExpired();
  
    const token = localStorage.getItem("auth_token");
  
    const response = await fetch(
      `${env.API_ENDPOINT_URL}/category/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    const data = await response.json();
    if (response.status === 401) {
      try {
        isTokenExpired(token);
        message.error(data.message);
      } catch (e) {
        window.location.replace("/app");
        throw new Error("Unauthorized");
      }
      throw new Error("Unauthorized");
    } else if (response.status === 201 || response.status === 200) {
      message.success(data.message);
    } else if (response.status === 400) {
      message.error(data.message);
    } else if (response.status === 403) {
      message.error(data.message);
    } else if (response.status === 404) {
      message.error(data.message);
    } else if (response.status === 500) {
      message.error(data.message);
    } else if (response.status === 503) {
      message.error("Server is down, please try again later");
    }
  };
  
  export const useRemoveCategory = () => {
    const queryClient = useQueryClient();
  
    return useMutation(({ id }) => removeCategory(id), {
      onSuccess: () => {
        queryClient.invalidateQueries("categoryList");
      },
      onError: (error) => {
        message.error(error.message || "An error occurred while fetching data");
      },
    });
  };