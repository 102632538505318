import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_LTR,
  DIR_RTL,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

// Usage
const currentTheme=JSON.parse(localStorage.getItem('currentTheme'));
const navtype=JSON.parse(localStorage.getItem('navType'));
const topnavColor=JSON.parse(localStorage.getItem('onTopNavColor'));
const topheadeerColor=JSON.parse(localStorage.getItem('onHeaderNavColor'));
const direction=JSON.parse(localStorage.getItem('direction'));
const navCollapse=JSON.parse(localStorage.getItem('navCollapse'));
const locale=JSON.parse(localStorage.getItem('locale'));
export const APP_NAME = "CHA";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const JOBS_PREFIX_PATH="/jobs"
export const THEME_CONFIG = {
  navCollapsed: navCollapse===true ? true : false,
   sideNavTheme: SIDE_NAV_LIGHT,
   locale: locale ? `${locale}` : "EN",
   navType: navtype==="TOP" ? NAV_TYPE_TOP : NAV_TYPE_SIDE,
   topNavColor: topnavColor ? `${topnavColor}` : "#3e82f7",
   headerNavColor: topheadeerColor ? `${topheadeerColor}` : "",
   mobileNav:false,
   currentTheme: currentTheme ? `${currentTheme}` : "light",
   direction: direction==="rtl" || locale==="DR" || locale==="PS" ? DIR_RTL : DIR_LTR,
};
