import React from "react";
import { Skeleton, Table } from "antd";
import { fetchUserPermissions } from "hooks/roles";

const CompletionTrackerTableSkeleton = () => {

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: () => (
        <Skeleton.Input style={{ width: 200 }} active size="default" />
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: () => (
        <Skeleton.Input style={{ width: 100 }} active size="default" />
      ),
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        align: "center",
        render: () => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton.Button
                style={{ width: 40, margin: "0 8px" }}
                active
                size="small"
                shape="circle"
              />
           <Skeleton.Button
                style={{ width: 40, margin: "0 8px" }}
                active
                size="small"
                shape="circle"
              />
            <Skeleton.Button
                style={{ width: 40, margin: "0 8px" }}
                active
                size="small"
                shape="circle"
              />
          </div>
        ),
      },
  ];

  const skeletonData = Array.from({ length: 5 }, (_, key) => ({ key }));

  return (
    <Table columns={columns} dataSource={skeletonData} pagination={false} />
  );
};

export default CompletionTrackerTableSkeleton;
