import {
    message,
    Input,
    Button,
    Modal,
    Form,
    DatePicker,
    Col,
    Row

} from "antd";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import { LoadingOutlined, EditOutlined, DeleteTwoTone } from "@ant-design/icons";
import { usePostTechnicalQuestion } from "hooks/humanResource/recruitment/interview/technicalQuestion/usePostQuestion";
import { useParams } from "react-router-dom";
import './components/style.css';
import { usePostCategory } from "hooks/humanResource/configPanel/category/usePostCategory";
import { useFetchProjectManagmentDistrict } from "hooks/projectManagementUnit/implementation/taskcompletion/useFetchProjectManagmentDistrict";
import { usePostTaskCompletion } from "hooks/projectManagementUnit/implementation/taskcompletion/usePostTaskCompletion";
import { useFetchFeedBack } from "hooks/projectManagementUnit/implementation/completionTracker/feedBack/useFechFeedBack";
import FeedBackTableSkeleton from "./components/tableSkeleton";
import FeedBackTable from "./components/feedBackTable";
import { usePostFeedBack } from "hooks/projectManagementUnit/implementation/completionTracker/feedBack/usePostFeedBack";
import { useQueryClient } from "react-query";

const { RangePicker } = DatePicker;

const FeedBackModal = ({ 
    isModalVisible,
    handleCancel,
    permissions,
    id
 }) => {
    const queryClient = useQueryClient();
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {data : feedbackData, isLoading, isError} = useFetchFeedBack(id);

    useEffect(() => {
        if (isModalVisible) {
          form.resetFields();
        }
      }, [isModalVisible]);

    const { mutate } = usePostFeedBack();

 
    const onFinish = (values) => {
  
        try{
        setIsSubmitting(true);
          
          const newData = {
            trackerEntry: id,
            feedback: values.feedback,
          };
    
          mutate({newData:newData},{
            onSuccess: () => {
              queryClient.invalidateQueries('completionTracker');
              queryClient.invalidateQueries('taskCompletion');
              setIsSubmitting(false);
              form.resetFields();
            },
            onError: (error) => {
              setIsSubmitting(false);
              message.error(
                error.message || 'An error occurred while fetching data'
              );
            },
          } );
        }catch(err){
            console.log(err);
        }
      };

    return (
        <div>
          
          <Modal
       title="Feedback"
       open={isModalVisible}
       onCancel={handleCancel}
       width={800}
       footer={false}
     >
       <Form onFinish={onFinish} form={form}>
         <Form.Item
           
           name="feedback"
           rules={[{ required: true, message: 'Please enter your feedback' }]}
         >
           <Input.TextArea rows={4} className="custom-input-style"/>
         </Form.Item>

         {permissions.includes('addProjectProgressTrackerTaskCompletionFeedback') && ( <Form.Item>
           <Button type="primary" htmlType="submit">
           {isSubmitting ? 'Please wait...' : 'Submit Feedback'}
           </Button>
         </Form.Item>)}
       </Form>
       <div>
          {isLoading ? <FeedBackTableSkeleton /> : <FeedBackTable
            data={feedbackData}
            isError={isError}
            permissions={permissions}
          />}
        </div>
     </Modal>
        </div>
    );
};

export default FeedBackModal;