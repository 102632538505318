import { useQuery,useMutation,useQueryClient } from "react-query";
import { message } from "antd";
import { env } from "configs/EnvironmentConfig";
import isTokenExpired from "hooks/tokenEx";

const postTaskCompletion = async(newData)=>{
    isTokenExpired();
  
    const token=localStorage.getItem('auth_token');
  
    const response = await fetch(`${env.API_ENDPOINT_URL}/pmu/project/implementation/completion-tracker`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(newData)
    });
  
    const data = await response.json();
  
    if (response.status === 401) {
      try {
        isTokenExpired(token);
        message.error(data.message);
      } catch (e) {
        window.location.replace('/app');
        message.error('Unauthorized');
      }
      message.error('Unauthorized');
    } else if (response.status === 201 || response.status === 200) {
      message.success(data.message);
    } else if (response.status === 400) {
      message.error(data.message);
    } else if (response.status === 403) {
      message.error(data.message);
    } else if (response.status === 404) {
      message.error(data.message);
    } else if (response.status === 500) {
      message.error(data.message);
    } else if (response.status === 503) {
      message.error("Server is down, please try again later")
    }
  }
  
 
  export const usePostTaskCompletion = () => {
    const queryClient = useQueryClient();
  
    return useMutation(
      ({newData}) => postTaskCompletion(newData), {
      onSuccess: () => {
        queryClient.invalidateQueries('taskCompletion');
      },
      onError: (error) => {
        message.error(
          error.message || 'An error occurred while fetching data'
        );
      },
    });
  };
