
import axios from 'axios';
import { env } from 'configs/EnvironmentConfig';
const token=localStorage.getItem('auth_token');
const permissions=JSON.parse(localStorage.getItem('permissions'));
export const fetchUserPermissions =   (role) => {
  
    return permissions;
  // try {
  //   const response = await axios.get(`${env.API_ENDPOINT_URL}/user-management/user-role`,{
  //     headers: {
  //       "Content-type": "application/json",
  //       "Authorization": `Bearer ${token}`
  //     }
  //   });
    
  //  return response.data;
  // } catch (error) {
  //   console.error('Error fetching permissions:', error);
  //   return [];
  // }
};


