import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "antd";
import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { fetchUserPermissions } from "hooks/roles";

const CompletionTrackerDocumentTable = ({ documents, onView, onDownload, onRemove }) => {


const [data, setData] = useState([]);

useEffect(() => {
  if (documents && documents.length > 0) {
    setData(documents);
  } else {
    setData([]);
  }
}, [documents]);

  const showDeleteConfirm = (record) => {
    Modal.confirm({
      title: "Are you sure you want to delete this document?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onRemove(record);
        
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record) => record.frontName || text,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
        title: "Action",
        key: "action",
        align: "center",
        render: (_, record) => (
          <span>
             <Button
                icon={<EyeOutlined />}
                onClick={() => onView(record)}
                style={{ marginRight: 8}}
              />
            <Button
                icon={<DownloadOutlined />}
                onClick={() => onDownload(record)}
                style={{ marginRight: 8}}
              />
           <Button
                icon={<DeleteOutlined />}
                onClick={() => showDeleteConfirm(record)}
                // style={{ color: "crimson",borderColor: "crimson" }}
              />
          </span>
        ),
      },
  ];

  return   <Table
              columns={columns}
              dataSource={data}
              rowKey={data => data._id}
              pagination={false}
              scroll={{ x: 'max-content' }}
/>
};

export default CompletionTrackerDocumentTable;
