import React from 'react'
import { Switch, Route, } from "react-router-dom";
import JobsViews from 'views/jobs-views'

export const JobsLayout = () => {
	return (
		<div className="auth-container">
			<Switch>
				<Route path="" component={JobsViews} />
			</Switch>
		</div>
	)
}


export default JobsLayout
